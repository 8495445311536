
import { defineComponent, onMounted, reactive } from "vue";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import headerFacture from "@/views/still/board/headerFacture.vue";
import headerOffre from "@/views/still/board/headerOffre.vue";
import headerContrat from "@/views/still/board/headerContrat.vue";
import mAxiosApi from "@/api";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "payment-methods",
  props: {
    cardClasses: String,
  },
  components: {
    headerFacture,
    headerOffre,
    headerContrat,
  },
  setup() {
    const router = useRouter();
    const state = reactive({
      list: [],
      loaderEnabled: true,
    });
    const { t } = useI18n()

    const capitalize = (val) => {
      return val.toUpperCase();
    };

    const translateDescription = (description) => {
      let translatable = ""

      if (description.indexOf("Total dû depuis le") !== -1) {
        translatable = "Total dû depuis le"
      }
      if (description.indexOf("Date d'échéance au") !== -1) {
        translatable = "Date d'échéance au"
      }
      if (description.indexOf("Offre valide jusqu'au") !== -1) {
        translatable = "Offre valide jusqu'au"
      }
      if (description.indexOf("Contrat arrivant à échéance le") !== -1) {
        translatable = "Contrat arrivant à échéance le"
      }

      return translatable !== "" ? t(translatable) + description.split(translatable)[1] : description
    }

    onMounted(async () => {
      MenuComponent.reinitialization();
      let rEch = await mAxiosApi.prototype.getAxios("/getEcheances");
      state.list = rEch.results;
      state.loaderEnabled = false;
    });
    const routeEcheance = (href, type) => {
      router.push({ name: type, params: { code: href } });
    };
    return {
      state,
      routeEcheance,
      capitalize,
      translateDescription,
    };
  },
});
