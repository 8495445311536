<template>
  <transition name="fade">
    <!--begin::List Widget 5-->
    <div class="card" :class="widgetClasses" v-if="!state.loaderEnabled">
      <!--begin::Header-->
      <div class="card-header align-items-center border-0 mt-4">
        <div class="card-title">
          <h3 class="fw-bolder mb-0">{{$t("Dernières mises à jours")}}</h3>
        </div>
      </div>
      <!--end::Header-->

      <!--begin::Body-->
      <div class="card-body pt-5">
        <!--begin::Timeline-->
        <div class="timeline-label">
          <!--begin::Item-->
          <div
            class="timeline-item"
            v-for="(item, index) in state.list"
            :key="index"
          >
            <!--begin::Label-->
            <div class="timeline-label fw-bolder text-gray-600 fs-6">
              {{ item.date }}
            </div>
            <!--end::Label-->

            <!--begin::Badge-->
            <div class="timeline-badge">
              <i class="fa fa-genderless fs-1" :class="item.class"></i>
            </div>
            <!--end::Badge-->

            <!--begin::Text-->
            <div class="fw-mormal timeline-content text-muted ps-3">
              <span v-if="item.type == 'Facture'" class="text-gray-800">
                {{$t("Une nouvelle facture est disponible")}}
                <b>
                  <router-link :to="item.url">
                    {{ item.id }}
                  </router-link></b
                >.
              </span>
              <span v-if="item.type == 'Offre'" class="text-gray-800">
                {{$t("Transmission d'une nouvelle offre commerciale")}}
                <b>
                  <router-link :to="item.url">
                    {{ item.id }}
                  </router-link> </b
                ><br />
                {{$t("concernant votre projet")}} <b>{{ item.desc2 }}</b
                >.
              </span>
            </div>
            <!--end::Text-->
          </div>
          <!--end::Item-->
        </div>
        <!--end::Timeline-->
      </div>
      <!--end: Card Body-->
    </div>
    <!--end: List Widget 5-->
  </transition>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, reactive } from "vue";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import mAxiosApi from "@/api";

export default defineComponent({
  name: "kt-activite",
  props: {
    widgetClasses: String,
  },
  setup() {
    const state = reactive({
      list: [],
      loaderEnabled: true,
    });
    onMounted(async () => {
      MenuComponent.reinitialization();
      let rEch = await mAxiosApi.prototype.getAxios("/getMAJ");
      state.list = rEch.results;
      state.loaderEnabled = false;
    });
    const list = ref([
      {
        date: "17.10.2021",
        class: "text-primary",
        description:
          "Une nouvelle facture disponible <b><a href='#'>FS21SN09008</a></b>.",
      },
      {
        date: "15.10.2021",
        class: "text-info",
        description:
          "Délai de livraison mis à jour sur la commande <b><a href='#'>HDF55640</a></b>.",
      },
      {
        date: "13.10.2021",
        class: "text-warning",
        description:
          "Nouveau contrat de support actif <b><a href='#'>ERT-74891</a></b>.",
      },
      {
        date: "09.10.2021",
        class: "text-success",
        description:
          "Transmission d'une nouvelle offre commerciale <b><a href='#'>JDOSHO-74891-2</a></b> concernant votre projet <b>153156456</b>.",
      },
      {
        date: "09.10.2021",
        class: "text-success",
        description:
          "Transmission d'une nouvelle offre commerciale <b><a href='#'>JDOSHO-74891</a></b> concernant votre projet <b>153156456</b>.",
      },
      {
        date: "09.10.2021",
        class: "text-success",
        description:
          "Transmission d'une nouvelle offre commerciale <b><a href='#'>JDOSHO-74891</a></b> concernant votre projet <b>153156456</b>.",
      },
      {
        date: "09.10.2021",
        class: "text-success",
        description:
          "Transmission d'une nouvelle offre commerciale <b><a href='#'>JDOSHO-74891</a></b> concernant votre projet <b>153156456</b>.",
      },
      {
        date: "09.10.2021",
        class: "text-success",
        description:
          "Transmission d'une nouvelle offre commerciale <b><a href='#'>JDOSHO-74891</a></b> concernant votre projet <b>153156456</b>.",
      },
    ]);

    return {
      list,
      state,
    };
  },
});
</script>
